/* Remove global styles that affect all elements */
/* * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
} */

/* Remove or comment out the body style if it's not needed globally */
/* body {
  font-family: "SF Pro Text";
  font-size: 20px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: hsl(256, 26%, 21%);
} */

/* Scope nav styles specifically to a class if needed */
.nav-container {
  width: 100%;
  max-width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 40px;
}

.nav-container ul {
  display: flex;
  gap: 1.25rem;
  list-style: none;
}

.link {
  text-decoration: none;
  padding: 0.5rem 0.75rem;
  font-size: 18px;
  font-weight: 600;
  color: black;
  position: relative;
  transition: color 0.25s;
}

.link:hover {
  color: #4F46E5;
}

.link::after {
  position: absolute;
  content: "";
  inset: 0;
  background-color: #4F46E5;
  scale: 1 0;
  z-index: -1;
  transition: 0.45s;
  border-radius: 10px;
}

.link:hover::after {
  scale: 1 1;
}