@import url(https://fonts.googleapis.com/css?family=Quattrocento+Sans);

/* Inline loader styles */
.loadingText {
  display: inline-block;
  white-space: nowrap;
  font-family: "Quattrocento Sans", sans-serif;
  color: #fff; /* White color for the text */
}

.loadingTextWords {
  display: inline-block;
  margin: 0 2px; /* Reduced margin for compact appearance */
  animation: blur-text 1.5s infinite linear alternate;
}

@keyframes blur-text {
  0% {
    filter: blur(0px);
  }
  100% {
    filter: blur(2px); /* Reduced blur for subtler effect */
  }
}