/* AnimatedButton.css */
.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 80% ;
}

.email-input {
  padding: 0.5rem;
  border: 2px solid #ccc;
  border-radius: 1.5rem;
  outline: none;
  position: absolute;
  right: 100%;
  width: 0;
  opacity: 0;
  transition:ease-in;
  overflow: hidden;
}

.email-input.active {
  width: 200px; /* Adjust width as needed */
  opacity: 1;
  margin-right: 1rem;
  right: calc(120px); /* Adjust spacing between input and button */

}

.notifyBtn {
  padding: 0.5rem 2rem;
  background-color: #4F46E5;
  color: white;
  border: none;
  border-radius: 1.5rem;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s, transform 0.3s, left 0.3s;
  position: relative;
  left: 0;
}

.notifyBtn.active {
  position: relative;
  left: 200px;
}


.notifyBtn:hover {
  background-color: indigo-700;
  transform: scale(1.05);
}
